import { withDependencies, named, optional } from '@wix/thunderbolt-ioc'
import {
	PageFeatureConfigSymbol,
	CompEventsRegistrarSym,
	Props,
	ViewModeSym,
	BrowserWindowSymbol,
} from '@wix/thunderbolt-symbols'
import { MenuContainerData, MenuContainerFactory } from './types'
import { Animations } from 'feature-animations'
import { SiteScrollBlockerSymbol } from 'feature-site-scroll-blocker'
import { AnimationHandler } from './AnimationHandler'
import { isSSR } from '@wix/thunderbolt-commons'

const menuContainer: MenuContainerFactory = (
	compEventsRegistrar,
	propsStore,
	viewMode,
	window,
	animations,
	siteScrollBlocker,
	featureConfig
) => {
	const animationHandler = animations && AnimationHandler(animations)

	const onToggleClick = (
		menuContainerId: string,
		menuContainerToggleId: string,
		menuContainerData: MenuContainerData,
		immediate: boolean = false
	) => {
		const menuToggleProps = propsStore.get(menuContainerToggleId)
		const isOpen = !menuToggleProps.isOpen
		const shouldUpdateVisibility = isOpen || immediate || !animationHandler

		const isMobile = viewMode === 'mobile'
		const { styles: existingStyles } = propsStore.get('STYLE_OVERRIDES_ID') || {}

		siteScrollBlocker && siteScrollBlocker.setSiteScrollingBlocked(isOpen, menuContainerId)
		!immediate &&
			animationHandler &&
			animationHandler.animate(menuContainerId, menuContainerData, isOpen, (reversed: boolean) => {
				const isClosed = reversed ? isOpen : !isOpen
				if (isClosed) {
					propsStore.update({
						[menuContainerId]: {
							isVisible: false,
						},
					})
				}
			})

		propsStore.update({
			[menuContainerId]: {
				isOpen,
				...(shouldUpdateVisibility && { isVisible: isOpen }),
			},
			[menuContainerToggleId]: {
				isOpen,
			},
			...(isOpen && {
				STYLE_OVERRIDES_ID: {
					styles: {
						...existingStyles,
						[menuContainerId]: {
							'--menu-height': !isSSR(window) && isMobile ? `${window!.innerHeight}px` : '100vh',
						},
					},
				},
			}),
		})
	}

	return {
		getSdkHandlers: () => ({
			openMenuContainer: (menuContainerId: string) => {
				const menuContainerData = featureConfig[menuContainerId]
				const menuContainerToggleId = featureConfig[menuContainerId].menuToggleId
				const menuToggleProps = propsStore.get(menuContainerToggleId)
				if (!menuToggleProps.isOpen) {
					onToggleClick(menuContainerId, menuContainerToggleId, menuContainerData)
				}
			},
			closeMenuContainer: (menuContainerId: string) => {
				const menuContainerData = featureConfig[menuContainerId]
				const menuContainerToggleId = featureConfig[menuContainerId].menuToggleId
				const menuToggleProps = propsStore.get(menuContainerToggleId)
				if (menuToggleProps.isOpen) {
					onToggleClick(menuContainerId, menuContainerToggleId, menuContainerData)
				}
			},
		}),

		pageWillMount: async () => {
			Object.entries(featureConfig).forEach(([menuContainerId, menuContainerData]) => {
				const menuContainerToggleId = (menuContainerData as MenuContainerData).menuToggleId

				const onKeyDown = (e: Event) => {
					const keyboardEvent = e as KeyboardEvent
					if (keyboardEvent.key === 'Enter' || keyboardEvent.key === ' ') {
						onToggleClick(menuContainerId, menuContainerToggleId, menuContainerData)
					}
				}

				const onMenuContainerClick = (e: Event) => {
					const anchor = getClosestAnchorAncestor(e.target as HTMLElement)
					if (anchor) {
						onToggleClick(menuContainerId, menuContainerToggleId, menuContainerData, true)
					}
				}

				compEventsRegistrar.register(menuContainerId, 'onClick', onMenuContainerClick)
				compEventsRegistrar.register(menuContainerToggleId, 'onKeyDown', onKeyDown)
				compEventsRegistrar.register(menuContainerToggleId, 'onClick', () => {
					onToggleClick(menuContainerId, menuContainerToggleId, menuContainerData)
				})
			})
		},
	}
}

const getClosestAnchorAncestor = (element: HTMLElement | null): HTMLElement | null => {
	if (!element || !element.tagName) {
		return null
	}
	if (element.tagName.toLowerCase() === 'a') {
		return element
	}
	return getClosestAnchorAncestor(element.parentNode as HTMLElement) // TODO: after the infra will load the closest api, use it instead.
}

export const MenuContainer = withDependencies(
	[
		CompEventsRegistrarSym,
		Props,
		ViewModeSym,
		BrowserWindowSymbol,
		optional(Animations),
		optional(SiteScrollBlockerSymbol),
		named(PageFeatureConfigSymbol, 'menuContainer'),
	],
	menuContainer
)
